import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Card, CardBody, Progress, CardTitle } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import { LiveSelectByToken } from "../../api-tasks/live";

import MegaMap from "../../components/MegaMap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

import ExpiryWarningModel from "../../components/ExpiryWarningModel";

class LiveMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liveUpdates: [],
      liveEpic: [],
      alertsWeeklyCount: [],
      selectedVehicles: [],
      running: 0,
      idle: 0,
      off: 0,
      expiredVehicles: [],
    };

    this.intervalId = null;
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidMount() {
    this.getLastUpdate();
    this.intervalId = setInterval(this.getLastUpdate, 2000);

    this.setExpiredVehicles();
  }

  // componentDidUpdate 
  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setExpiredVehicles();
    }
  }

  setExpiredVehicles = () => {
    // update state with this.props.vehiclesAll.reminigDays < 0
    const expiredVehicles = this.props.vehiclesAll.filter((x) => x?.reminigDays && x.reminigDays < 0);
    this.setState({ expiredVehicles });
  };

  getLastUpdate = () => {
    try {
      const user = this.props.user;

      LiveSelectByToken(user.userToken)
        .then((res) => {
          if (res) {
            if (Array.isArray(res)) {
              const epic = res.map((row) => {
                return {
                  ...row,
                  asset: this.props.vehiclesAll.filter((x) => x.autoid === row.vehicle_id)[0],
                };
              });
              this.updateStatics(res);
              this.setState({ liveUpdates: res, liveEpic: epic });
            } else {
              this.props.authApiAccessCheck(this.props.history, res);
            }
          }
        })
        .catch((err) => {
          console.log("live-map.getLastUpdate", err);
        });
    } catch (error) {
      console.log("the getLastUpdate", error);
    }
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });
  };

  updateStatics = (liveUpdates) => {
    if (Array.isArray(liveUpdates)) {
      const epic = liveUpdates.map((row) => {
        return {
          ...row,
          asset: this.props.vehiclesAll.filter((x) => x.autoid === row.vehicle_id)[0],
        };
      });

      let off = 0;
      let idle = 0;
      let running = 0;
      epic.forEach((elInfo) => {
        if (!elInfo.ignition) {
          off++;
        } else {
          if (elInfo.speed === 0) {
            // idle
            idle++;
          } else {
            running++;
          }
        }
      });

      this.setState({ running, idle, off });
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Fragment>
        <ExpiryWarningModel expiredVehicles={this.state.expiredVehicles} subscriberId={this.props?.userDetails?.subscriberId  } />
        <Card className="mb-4 d-none">
          <CardBody>
            <CardTitle>
              {" "}
              <IntlMessages id="dashboard.vehicles" />{" "}
            </CardTitle>
            <Row>
              <Colxx xl="3" lg="6" className="mb-4">
                <Card className="bg-primary">
                  <CardBody>
                    <h5 className="mb-0 card-title">
                      <IntlMessages id="dashboard.total" />
                    </h5>
                    <p className="lead mb-1 value">
                      <i className="iconsminds-jeep" /> {this.props.vehiclesAll.length}
                    </p>

                    <Progress value={100} />
                  </CardBody>
                </Card>
              </Colxx>

              <Colxx xl="3" lg="6" className="mb-4">
                <Card className="bg-success text-white">
                  <CardBody>
                    <h5 className="mb-0 card-title">
                      <IntlMessages id="dashboard.running" />
                    </h5>
                    <p className="lead mb-1 value">
                      <i className="simple-icon-control-play" /> {this.state.running}
                    </p>

                    <Progress value={(this.state.running / this.props.vehiclesAll.length) * 100} />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xl="3" lg="6" className="mb-4">
                <Card className="bg-danger text-white">
                  <CardBody>
                    <h5 className="mb-0 card-title">
                      <IntlMessages id="dashboard.idle" />
                    </h5>
                    <p className="lead mb-1 value">
                      <i className="simple-icon-control-pause" /> {this.state.idle}
                    </p>

                    <Progress value={(this.state.idle / this.props.vehiclesAll.length) * 100} />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xl="3" lg="6" className="mb-4">
                <Card className="bg-dark2 text-white">
                  <CardBody>
                    <h5 className="mb-0 card-title">
                      <IntlMessages id="dashboard.off" />
                    </h5>
                    <p className="lead mb-1 value">
                      <i className="simple-icon-ban" /> {this.state.off}
                    </p>

                    <Progress value={(this.state.off / this.props.vehiclesAll.length) * 100} />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4">
              <CardBody className="">
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      onChange={this.handleOnSelectedVehicleChange}
                      placeholder={intl.formatMessage({
                        id: "dashboard.filter-vehicles",
                      })}
                    />
                  </Colxx>
                </Row>

               
                <MegaMap
                  selectedVehicles={this.state.selectedVehicles}
                  vehiclesAll={this.props.vehiclesAll}
                  liveUpdates={this.state.liveUpdates}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user, details } = authUser;
  const { locale } = settings;
  return { vehiclesAll: all, user, locale, userDetails: details };
};

export default connect(mapStateToProps, { authApiAccessCheck })(LiveMap);
