import React from "react";
import { Table } from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";

class GeofenceNotIn extends React.Component {
  render() {

    
    

    return (
      <>
        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="day-summary.vehicle" />
              </th>
             
             
               
            </tr>
          </thead>
          <tbody>
            {this.props.allRows.map((item, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.plate_number}</td>
                

                
                
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default GeofenceNotIn;
