import React from "react";
import { Table } from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";

class GeofenceTrips extends React.Component {
  render() {

     
    

    return (
      <>
        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="day-summary.vehicle" />
              </th>
              <th>event count</th>
              <th>
                trips
              </th>
               
            </tr>
          </thead>
          <tbody>
            {this.props.allRows.map((item, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.plate_number}</td>
                <td>{item.geofenceEvents.length}</td>

                <td>{( item.geofenceEvents.length / 2).toFixed(0)  }</td>
                
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default GeofenceTrips;
