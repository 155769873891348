import React from "react";
import { Table } from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";
import LocationName from "../../components/LocationName";

class GeofenceTableInAndOut extends React.Component {
  render() {
    return (
      <Table responsive hover bordered striped>
        <thead>
          <tr>
            <th>#</th>
            <th>
              <IntlMessages id="day-summary.vehicle" />
            </th>
            <th>
              <IntlMessages id="day-summary.place" />
            </th>

            <th>
              <IntlMessages id="day-summary.entry" />
            </th>
            <th>
              <IntlMessages id="day-summary.driver" />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.allRows.map((item, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{item.vehicleName}</td>
              <td>
                {item.time}
                <br />
                <LocationName place={item.place} geo={item.geo} objectItem={item} showGoogle />
              </td>

              <td>{item.typeText}</td>
              <td>{item.driverName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default GeofenceTableInAndOut;
