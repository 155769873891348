import React from "react";
import { Modal, ModalHeader, ModalBody, Button, Row } from "reactstrap";
import { Colxx } from "../components/common/CustomBootstrap";
import IntlMessages from "../helpers/IntlMessages";

// show notice for below subscriberIds
const subscriberIds = [66, 120];

class ExpiryWarningModel extends React.Component {
  render() {
    const expiredVehicles = this.props.expiredVehicles || [];

    return (
      <Modal isOpen={this.props.isOpen} size="lg" id="expired.notice" toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Subscription expiry notice</ModalHeader>
        <ModalBody className="">
          <Row>
            <Colxx xxs="12" className="mb-1">
              <h3>
                You have vehicles with expired subscription. Please renew the subscription to avoid service
                disruption.
              </h3>

                {/* add style display: flex;   flex-wrap: wrap;  and gap: 10px; */}
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }} className="bg-warning text-white p-4">
                {expiredVehicles.map((vehicle, index) => (
                  <div key={index}>
                    <span>{vehicle.plate_number}</span>
                    <br />
                  </div>
                ))}
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

class ExpiryWarningModelBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = { mapModelOpen: false, isNoticeActive: false };
    this.timer = null;
    this.expiredVehicles = [];
  }

  componentDidMount() {
    this.startTimer();

    // set state with expiredVehicles
    this.setState({ expiredVehicles: this.props.expiredVehicles || [] });

    this.setNoticeActive();
  }

  // on component update
  componentDidUpdate(prevProps) {
    if (prevProps.expiredVehicles !== this.props.expiredVehicles) {
      this.setState({ expiredVehicles: this.props.expiredVehicles || [] });
      this.setNoticeActive();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  // active notice if expiredVehicles.length > 0
  setNoticeActive = () => {
    setTimeout(() => {

      // if not this.props.subscriberId is in subscriberIds then return
      if (!subscriberIds.includes(this.props.subscriberId)) {
        return;
      }

      const isNoticeActive = this.state.expiredVehicles.length > 0;
      this.setState({ isNoticeActive, mapModelOpen: isNoticeActive });

      if (isNoticeActive) {
        this.startTimer();
      }
    }, 1000);
  };

  startTimer = () => {
    if (this.state.isNoticeActive) {
      this.timer = setInterval(this.openPopup, 10 * 60 * 1000);
    }
  };

  openPopup = () => {
    if (this.state.mapModelOpen) {
      return;
    } // if model is open

    this.setNoticeActive();
  };

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <ExpiryWarningModel
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button color="primary" outline size="xs" className="mb-2" onClick={this.toggleLarge}>
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
          expiredVehicles={this.state.expiredVehicles}
        />
      </React.Fragment>
    );
  }
}

export default ExpiryWarningModelBase;
